import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Signup from "../components/modals/Signup";
import Login from "../components/modals/Login";
import Profile from "../components/modals/Profile";
import Product from "../components/Product.vue";
import Buy from "../components/modals/Buy.vue";
import Confirm from "../components/modals/Confirm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/product/:id",
    name: "Product",
    component: Product,
    props: true,
    },
    {
      path: "/buy/:id",
      name: "Buy",
      component: Buy,
      props: true,
      },
    {
      path: "/confirm",
      name: "Confirm",
      component: Confirm,
      props: true,
      },

      //404 page
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../components/NotFound.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;