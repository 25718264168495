<template>
  <div class="backdrop">
    <div class="b-modal">
      <div class="modal-header">
        <div class="modal-title text-white">
          LOGIN
        </div>
        <p class="text-white"><span @click.self="closeModalLogin()">x</span></p>
      </div>
      <div>
          <form class="form" @submit.prevent="onLogin">
            <div class="alert alert-danger" v-if="errors.login.show">
                {{ errors.login.msg }}
            </div>
            <label class="label pt-4 pb-2 container d-flex">Email Address/Mobile Number:</label>
            <input class="container p-2 col-9 d-flex justify-content-center" type="text" v-model="login.loginId" required>



            <b-card class="otp-container">
            <div>
              <label for="login">PIN:</label>
            </div>
            <div class="otp-wrapper">
              <div class="pin">
                <input
                  id="pin1"
                  type="text"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="login.pinArr[0]"
                  class="otp-input"
                  @keydown="pinBack($event, 1)"
                />
              </div>

              <div class="pin">
                <input
                  id="pin2"
                  type="text"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="login.pinArr[1]"
                  class="otp-input"
                  @keydown="pinBack($event, 2)"
                />
              </div>

              <div class="pin">
                <input
                  id="pin3"
                  type="text"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="login.pinArr[2]"
                  class="otp-input"
                  @keydown="pinBack($event, 3)"
                />
              </div>

              <div class="pin">
                <input
                  id="pin4"
                  type="text"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="login.pinArr[3]"
                  class="otp-input"
                  @keydown="pinBack($event, 4)"
                />
              </div>
            </div>
            <div class=" d-flex justify-content-between">
              <!-- <a class="forgotpin" href="#">Forgot PIN?</a> -->
              <!-- <a class="fxn" @click.self="showPin()">Show PIN</a> -->
            </div>
          </b-card>
          <div class="pt-5">
            <div class="d-grid gap-2 mx-auto col-6 actions">
              <router-link to="/" v-if="login.state == 'login'"><b-button
                type="button"
                class="btn btn-light btn-block"
                @click="loggedIn"
              >
                {{ loginLabel }}
              </b-button></router-link>
              <router-link to="/signup"><b-button
                type="button"
                variant="light"
                class="btn-dark btn-block"
              >
                {{ signupLabel }}
              </b-button></router-link>
              
            </div>
          </div>
      
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'Login',
    data() {
        return{
            signupLabel: "SIGN UP",
            loginLabel: "LOG IN",
             login: {
                state: 'login',
                loginId: "",
                pinArr: [],
              
            },
            errors: {
                login: {
                msg: "",
                show: false,
                loginId: ""
            }
        }

        }
    },
    methods: {
        closeModalLogin() {
        this.$emit("close");
        },

        pinBack: function (event, currrentBox) {
            if (event.keyCode === 8) {
                if (currrentBox != 1) {
                setTimeout(function () {
                    document.getElementById("pin" + (currrentBox - 1)).focus();
                }, 50);
                }
            }

            if (
                (event.keyCode >= 48 && event.keyCode <= 57) ||
                (event.keyCode >= 96 && event.keyCode <= 105) ||
                event.keyCode == 229
            ) {
                setTimeout(function () {
                document.getElementById("pin" + (currrentBox + 1)).focus();
                }, 50);
            }
        },

        loggedIn(e) {
            e.preventDefault();
            const params = {
                  loginHost: "test.cloudchain.it",
                  loginId: this.login.loginId.emailOrmobile,
                  loginPass: this.login.pinArr.one+ this.login.pinArr.two + this.login.pinArr.three + this.login.pinArr.four,
                  }
                

                const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');
          

                const options = {
                  method: 'POST',
                  headers: { 'content-type': 'application/x-www-form-urlencoded' },
                  data,
                  url: '/cx/login',
                };
                this.axios(options)
                    .then((response) => {
                        // Success Code goes here
                        this.$router.push({ name: 'Home' });
                       
                    })
                    .catch((error) => {
                        // Error code goes here
                        if(error.response.data.fieldErrors != undefined) {
                            this.errors.login.msg = error.response.data.fieldErrors[0].defaultMessage
                        } else {
                            this.errors.login.msg = error.response.data.msgText;
                            
                        }
                        this.errors.login.show = true;
                        console.log(error)
                        
                    });
            }

    },

    
}
</script>
<style scoped>
.b-modal{
    width: 350px;
    height: auto;
    margin: 10px auto !important;
    background: white;
    border-radius: 10px;
    z-index: 2;
    left:0;
    top:0;
    bottom:0;
    right:0;
    padding-bottom: 10px;
  }
.otp-container {
  border: none;
  margin: 0 auto !important;
  width: 90%;
}
.label{
    margin-left: 25px;
}
.otp-wrapper {
  margin: 0 auto !important;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.pin {
  margin: 0.01rem;
}
.otp-input {
  width: 50px;
  text-align: center;
  border-radius: 6px;
  box-shadow: none;
  font-size: 3em;
  border: grey(162, 113, 219) 0.3px solid;
  background: transparent;
}
.btn-block{
    width: 100%;
}
.btn-light {
  background: grey;
  border-color: #3f3f3f;
  color: white;
}
.btn-light:hover {
  background: #3f3f3f;
  color: white;
}
.btn-dark:hover {
  background: #3f3f3f;
  color: white;
}
.fxn{
    cursor: pointer;
}
</style>
