<template>
  <div class="main">
    <Dashboard></Dashboard>
    <!-- <Tabs></Tabs> -->
    <product-List-one></product-List-one>
    <Footer/>
    
    <router-view></router-view>
  </div>
</template>

<script>
import Dashboard from "../components/Dashboard";
// import Tabs from "../components/Tabs";
import ProductList from "../components/ProductList";
import Footer from "../components/Footer";

export default {
  name: "Home",
  components: {
    Dashboard,
    // Tabs,
    "product-List-one": ProductList,
    Footer
  
  },
};
</script>
<style scoped>
</style>