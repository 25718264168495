<template>
  <div class="containerBG d-flex align-items-stretch flex-wrap">
    <h2 class="text-white">404</h2>
    <h3 class="text-white">Page not found</h3>
    <router-link :to="{ name: 'Home' }">
      <h5>Please click this link to go to homepage</h5>
    </router-link>
  </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>