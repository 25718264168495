<template>
  <div class="backdrop">
    <div class="b-modal">
      <div class="modal-header">
        <div class="modal-title text-white">
          LOGIN
        </div>
        <p class="text-white"><span @click.self="closeModal">x</span></p>
      </div>
      <div>
        <form @submit="loggedIn">
          <b-button-group class="form-switcher">
            <b-button
              class="btn"
              variant="light"
              @click="flip('mobile', $event)"
            >
              <a href="" id="mobile-form">Mobile number</a>
            </b-button>
            <b-button
              class="btn"
              variant="light"
              @click="flip('email', $event)"
            >
              <a href="" id="email-form">Email Address</a>
            </b-button>
          </b-button-group>

          <div
            class="form-mobile pt-2"
            :class="{ active: active == 'mobile' }"
            id="form-mobile"
          >
            <label for="login">Mobile Number</label>
            <div class="form-group-mobile">
             

              <div class="inputMobile">
                <b-input
                  for="login"
                  class="inputMobile"
                  type="text"
                  name="mobile"
                  v-model.number="login.emailOrMobile"
                  @keyup.enter="loggedIn('mobile', $event)"
                />
              </div>
            </div>
          </div>

          <!--email-->
          <div
            class="form-email pt-2"
            id="form-email"
          >
            <label for="login">Email Address:</label>
            <div class="inputEmail">
              <b-input
                class="inputMobile"
                type="email"
                name="email"
                v-model="login.emailOrMobile"
                @blur="validateEmail"
                @keyup.enter="loggedIn('email', $event)"
              />
            </div>
            <p class="help is-danger" v-if="errors.inputEmail">
              Please enter a valid email address.
            </p>
          </div>

          <!--PIN-->
          <b-card class="otp-container">
            <div>
              <label for="login">PIN:</label>
            </div>
            <div class="otp-wrapper">
              <div class="pin">
                <input
                  id="pin1"
                  type="password"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="pinArr[0]"
                  class="otp-input"
                  @keydown="pinBack($event, 1)"
                />
              </div>

              <div class="pin">
                <input
                  id="pin2"
                  type="password"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="pinArr[1]"
                  class="otp-input"
                  @keydown="pinBack($event, 2)"
                />
              </div>

              <div class="pin">
                <input
                  id="pin3"
                  type="password"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="pinArr[2]"
                  class="otp-input"
                  @keydown="pinBack($event, 3)"
                />
              </div>

              <div class="pin">
                <input
                  id="pin4"
                  type="password"
                  autocomplete="off"
                  maxlength="1"
                  v-model.number="pinArr[3]"
                  class="otp-input"
                  @keydown="pinBack($event, 4)"
                />
              </div>
            </div>
            <div class="forgotpin">
              <a href="#">Forgot PIN?</a>
            </div>
          </b-card>
          <div>
            <div class="d-grid gap-2 mx-auto col-6 actions">
              <b-button
                type="button"
                class="btn btn-light btn-block"
                @click="loggedIn"
              >
                {{ loginLabel }}
              </b-button>
              <b-button
                type="button"
                variant="light"
                class="btn-dark btn-block"
              >
                {{ signupLabel }}
              </b-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      isUserLoggedIn: "account login",
      signupLabel: "SIGN UP",
      loginLabel: "LOG IN",
      inputMobile: "",
      inputEmail: "",
      pinArr: [],
      userCountry: "PH",
      mobileCode: "63",
      active: "mobile",
      showCountry: false,
      submitted: null,
      login: {
          customerName:"",
          emailOrMobile:"",
          message: "",
        },
        errors: {
          login: {
            show:false,
            msg: ""
          }
        },
    };
  },
  
  mounted() {
    this.$store.dispatch("login");
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    // flip: function (which, e) {
    //   e.preventDefault();
    //   if (which !== this.active) {
    //     this.active = which;
    //   }
    // },
    
    validateEmail: function () {
      this.isValid = this.validEmail;
      this.errors.inputEmail = !this.isValid;
    },
    pinBack: function (event, currrentBox) {
      if (event.keyCode === 8) {
        if (currrentBox != 1) {
          setTimeout(function () {
            document.getElementById("pin" + (currrentBox - 1)).focus();
          }, 50);
        }
      }

      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode == 229
      ) {
        setTimeout(function () {
          document.getElementById("pin" + (currrentBox + 1)).focus();
        }, 50);
      }
    },
    loggedIn(e) {
      e.preventDefault();

      this.$store.dispatch("login", {
        mobileCode: this.mobileCode,
        pinArr: this.pinArr,
        loginId: this.loginId,
      });

      this.login;
    },
  },
};
</script>
<style scoped>
.b-modal{
    width: 350px;
    height: 500px;
    margin: 100px auto;
    background: white;
    border-radius: 10px;
    z-index: 2;
  }
.otp-container {
  border: none;
  margin: 0 auto !important;
  width: 90%;
}
.form-mobile,
.form-email {
  margin: 0 auto;
  padding: 0 30px;
  display: none;
}
.form-email.active,
.form-mobile.active {
  display: block;
}

.form-switcher {
  width: 100%;
  margin: 0;
  padding: 0;
}

.form-switcher .btn {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  margin: 0;
}

.form-switcher .btn a {
  width: 100%;
  display: block;
  color: black;
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  background-color: transparent;
}

.form-switcher .btn a.active {
  background-color: #141414 !important;
}
.otp-wrapper {
  margin: 0 auto !important;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.pin {
  margin: 0.25rem;
}
.otp-input {
  width: 50px;
  text-align: center;
  border-radius: 6px;
  box-shadow: none;
  font-size: 3em;
  border: grey(162, 113, 219) 0.3px solid;
  background: transparent;
}
.user-country-flag {
  width: 30px;
  height: 20px;
  padding: 3px;
}
.b-select {
  border: rgb(177, 174, 174) 0.3px solid;
  border-radius: 6px;
}
.form-group-mobile {
  display: flex;
}
.btn-light {
  background: grey;
  border-color: #3f3f3f;
  color: white;
}
.btn-light:hover {
  background: #3f3f3f;
  color: white;
}
.btn-dark:hover {
  background: #3f3f3f;
  color: white;
}
</style>
