import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "./assets/global.css";
import router from "./router";
import { store } from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from 'vuelidate'


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);

Vue.config.productionTip = false

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");

new Vue({
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
