<template>
  <div class="col-4 mt-3">

    <div class="prodcard">
        <router-link :to="{name: 'Product', params:{ id:product.id } }">
            <img class="img-card overlay" @click.prevent="viewProduct()" :src="product.image"/>
        </router-link>
        <!-- <img class="img-card overlay" :src="product.image"/> -->
        <div class="details">
            <strong><h5 class="name">{{product.title}}</h5></strong>
            <p class="description">{{product.description}}</p>

            <div class="buy">
                <div class="size">
                    <p>{{product.size}}</p>
                </div>
                <div class="img-btn" shift-h="80" shift-v="50" ><router-link :to="{ name: 'Buy',params: { id: product.id } }">
                    <img @click.self="buyItem()" src="@/assets/buy.png"/></router-link>
                </div>
            </div>
            <div>
                <Buy @close="buyItem" v-if="showBuyModal"></Buy>
            </div>
            <Product @close="viewProduct" v-if="goToProduct"> </Product>

        </div>
        </div>
    </div>
  
</template>
<script>

import Buy from './modals/Buy.vue';
import Product from './Product.vue';

export default {
  components: { Buy, Product },
  props: ["product", "id"],
  name: 'ProductCard',
  
  data() {
    return {
     showBuyModal: false,
     goToProduct: false,
   
    }
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
    
  },
  
  methods: {

      buyItem(){
          this.showBuyModal = !this.showBuyModal;

          let myCart = {
                id: this.product.id,
                image: this.product.image,
                title: this.product.title,
                size: this.product.size,
                description: this.product.description,
                quantity: 1
          };
          localStorage.setItem('cart', JSON.stringify(myCart));
          console.log (myCart);
      },

      viewProduct() {
        this.goToProduct = !this.goToProduct;
        let myView = {
                id: this.product.id,
                image: this.product.image,
                title: this.product.title,
                size: this.product.size,
                description: this.product.description,
          };
          localStorage.setItem('product', JSON.stringify(myView));
          console.log (myView);
      }
  }

};
</script>

<style scoped>
.prodcard {
  background: url('../assets/artbg.png'), no-repeat;
  width: 19.3rem;
  height: 26.3rem;
  cursor: pointer;
  
}
.img-card{
    object-fit: cover;
    width: 16.6rem;
    height: 17.5rem;
    position: relative;
    left: 25px;
    right: 17px;
    top: 30px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.details{
    padding: 20px;
    color: white;
}
.buy{
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #b6b6b6;
}



</style>