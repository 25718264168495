import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex, axios);

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    product: [],
    products:[
      {id: '1',title: '2018',size: '60 x 50 inches',description: 'Mixed media on canvas', image:'/assets/salArt/2018.jpg'},
      // {id: '2',title: 'Untitled No. 1',size: '48 x 36 inches',description: 'Mixed media on canvas', image:'/assets/salArt/48x36.jpg'},
      {id: '3',title: 'Untitled',size: '19.5 x 13.5 inches',description: 'Oil on canvas', image:'/assets/salArt/1.jpg'},
      {id: '4',title: 'Untitled No.2',size: '48 x 36 inches',description: 'Mixed media on canvas', image:'/assets/salArt/Untitled No. 2.jpg'},
      {id: '5',title: 'Cosmic Series No.2',size: '4 x 3 ft.',description: 'Acrylic on canvas board', image:'/assets/salArt/Cosmic Series No 2.png'},
      {id: '6',title: 'Destination',size: '36 x 28.5 inches',description: 'Mixed media on canvas on board', image:'/assets/salArt/Destination.png'},
      {id: '7',title: 'Distraction',size: '4 x 3 ft.',description: 'Mixed media on canvas', image:'/assets/salArt/Distraction.png'},
      {id: '8',title: 'Feminine',size: '36 x 24 inches',description: 'Acrylic on canvas', image:'/assets/salArt/Feminine.png'},
      {id: '9',title: 'Untitled',size: '36 x 28.5 inches',description: 'Mixed media on canvas on plyboard', image:'/assets/salArt/Untitled 4.jpeg'},
      {id: '10',title: 'Struggle',size: '48 x 36 inches',description: 'Mixed media on canvas on plyboard', image:'/assets/salArt/Struggle.jpg'},
      {id: '11',title: 'Symphony',size: '36 x 24 inches',description: 'Acrylic on canvas', image:'/assets/salArt/Symphony.png'},
      {id: '12',title: 'Transcience',size: '5 x 4 ft.',description: 'Acrylic on canvas on board', image:'/assets/salArt/Transcience.png'},
      {id: '13',title: 'Untitled',size: '24 x 24 inches',description: 'Mixed media', image:'/assets/salArt/Untitled 2.png'},
      {id: '14',title: 'Untitled',size: '30 x 24 inches',description: 'Acrylic on canvas', image:'/assets/salArt/Untitled.png'},
      {id: '15',title: 'Zest',size: '24 x 24 inches',description: 'Acrylic on canvas', image:'/assets/salArt/Zest.png'},
      {id: '16',title: 'Empyreal',size: '4 x 3 ft.',description: 'Mixed media on canvas on board', image:'/assets/salArt/Empyreal.jpeg'},
      {id: '17',title: 'Glory',size: '24 x 36 inches',description: 'Acrylic on canvas', image:'/assets/salArt/Glory.jpeg'},
      {id: '18',title: 'Message',size: '48 x 36 inches',description: 'Mixed media on canvas on plyboard', image:'/assets/salArt/Message.jpeg'},
      {id: '19',title: 'Bella',size: '24 x 36 inches',description: 'Acrylic on canvas', image:'/assets/salArt/Bella.jpeg'},
      {id: '20',title: 'Blah Blah Blah',size: '5 x 4 ft.',description: 'Mixed media on canvas', image:'/assets/salArt/Blah.jpeg'},
      {id: '21',title: 'Couple',size: '48 x 36 inches',description: 'Mixed media on canvas on board', image:'/assets/salArt/Couple.jpeg'},
      {id: '22',title: 'Tranquility',size: '36 x 36 inches',description: 'Mixed media on canvas', image:'/assets/salArt/Tranquility.jpeg'},
    ],
      productId:"",
      login:{
        customerName:"",
        emailOrMobile:"",
        message:""
      },
    cart: [],
    confirmDetails:[]
   
  },
  getters:{
    
  },
  mutations: {
    GET_PRODUCT(state, payload){
      state.product = payload;
    },
    INIT_CART(state, payload) {
      state.cart = payload;
    },
    CONFIRM(state, payload){
      state.confirmDetails = payload;
    }
  },
  actions: {
    login({commit}, login){
      commit("LOGIN", login)
    },

    viewProduct({ commit },id, image, title, size, description) {
      commit("GET_PRODUCT", {id, image, title, size, description
      })
    },

    buyItem({commit}, {id, image, title, size, description, quantity}
      ) {
      commit("INIT_CART", {id, image, title, size, description, quantity})
    },

    confirmBuy({commit},{productId, customerName, emailOrMobile, message,}
      ) {
      commit("CONFIRM",{productId, customerName, emailOrMobile, message,})
    }

  },
  modules: {},
});