<template>
    <div>
<nav class="navbar navbar-light bg-light">
      <div class="container-fluid container col-10">
        <a href="/" class="navbar-brand"><img src="@/assets/logo.png" alt="HOME" title="HOME"></a>
        
        <div class="d-flex container align-items-stretch flex-wrap">
            <ul class="navbar-nav mx-auto mt-2 mt-lg-0 d-flex flex-wrap">
              <li class="nav-item active">
                <a class="nav-link" onclick="window.open('https://www.salsponceenrile.com/')">ABOUT</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" onclick="window.open('https://www.salsponceenrile.com/work')">WORK</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" onclick="window.open('https://www.salsponceenrile.com/exhibits')">EXHIBITS</a>
              </li>
            </ul>
            
            <form class="form-inline my-2 my-lg-0 flex-wrap">
              <a class="btn btn-primary mr-sm-2" type="button" href="mailto:sales@salsponceenrile.com">sales@salsponceenrile.com</a>
              <button class="btn btn-primary mr-sm-2" type="button" onclick="window.open('https://www.facebook.com/SalPonceEnrile')"><img src="@/assets/fb-icon.png"></button>
              <button class="btn btn-secondary my-2 my-sm-0" type="button" onclick="window.open('https://www.instagram.com/salsponceenrile/')"><img src="@/assets/email-icon.png"></button>
            </form>
            
        </div>
      </div>
    </nav>
  </div>        
</template>

<script>
export default {
  name: 'Header',
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navbar {
  display: flex;
  flex-wrap: wrap;
  background: #141414 !important;
  width: 100%;
  height: auto;
  position: relative;
}
.navbar-brand img{
  width: 8em;
  left: 10px;
  right: 0;
  top: 0;
  bottom: 0 !important;
  position: absolute;
}
.navbar-nav, li .nav-item, a{
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  color: white !important;
  font-size: 15px !important;
  font-weight: 400;
  bottom: 0 !important;
  cursor: pointer;
  padding: 10px 20px !important;
}
.nav-link:hover{
  color: black !important;
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;
}
.nav-link:focus{
  color: black !important;
  background-color: white;
  border-radius: 10px;
}
.nav-item{
  padding: 1rem !important;
  align-items: center !important;
}
.form-inline{
  padding: auto 10px !important;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  float: right !important;
}
.btn-primary{
  background-color: transparent !important;
  border: transparent !important;
  font-size: 2 rem !important;
}
.btn-primary:hover{
  color: white !important;
}
.btn-secondary{
  background-color: transparent !important;
  border: transparent !important;
  font-size: 2 rem !important;
}
.btn-secondary:hover{
  color: white !important;
} 

</style>
