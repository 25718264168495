<template>
   <div>
        <b-card class="col-6">
            <b-form-group class="text-dark"
                label="Firstname"
                label-for="first"
                >
                <b-form-input id="first" v-model="login.customerName.first" required ></b-form-input>
            </b-form-group>

            <b-form-group class="text-dark pt-3"
                label="Lastname"
                label-for="last"
               >
                <b-form-input id="last" v-model="login.customerName.last" required ></b-form-input>
            </b-form-group>

        </b-card>
           
  </div>
</template>

<script>


export default {
    name: "Profile",
    
  data() {
    return {
      showProfileModal: false,
      
      
    };
  },
  methods: {
    closeBtnProfile() {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>
.done-btn{
    display: flex;
    justify-content: center;
    padding: 30px;
}

</style>